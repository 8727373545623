<template>
  <div>
    <div class="bt-home" :style="'height:' + conHeight">
      <swiper
        :key="timer"
        id="swiperBox"
        :options="swiperOptions"
        ref="mySwiper"
        :style="'height:' + conHeight"
        style="overflow: hidden"
      >
        <swiper-slide style="position: relative">
          <div v-show="swiperIndex == 0" style="width: 100%;height:100%;">
            <el-image
              style="width: 100%; height: 100%"
              :src="bannerImage"
              fit="fill"
            ></el-image>
            <div class="banner-title">
              <div class="bt-banner-title-text">
                栢田医疗
              </div>
              <div class="bt-banner-title1">BAITIAN  HEALTHCARE</div>
              <div class="bt-banner-title2">科技领航 聚智服务</div>
              <div class="bt-banner-title3">全域医疗技术服务商</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="bt-introduce-container">
          <div v-if="swiperIndex == 1" class="bt-introduce">
            <div class="bt-home-title">
              集团介绍<span class="bt-home-subtitle">GROUP INTRODUCTION</span>
            </div>
            <div class="bt-introduce-content">
              <div class="bt-introduce-text">
                <div class="bt-name">云南栢田医疗科技有限公司</div>
                <div class="bt-introduce-content-text bt-introduce-content-text0">
                  是以信息技术的医疗应用和医疗设备维保为核心业务的综合性服务企业;多年来在医疗器械销售、维保、检测、报废全流程追溯管理中实践和总结，打造了完整的业务闭环。
                </div>
                <div class="bt-introduce-content-text bt-introduce-content-text0">
                  公司经过近30年的经营和发展，形成了以医疗设备维保为核心，覆盖医疗器械第三方检测、医疗器械生命周期信息系统、医疗器械高值耗材供应和配送的全面业务体系，公司以“科技领航，聚智服务”为指引，把追求“提供惠及全民的医疗服务，助力医卫行业信息技术发展”作为企业愿景。已成为云南医疗设备维保行业首家获得认证的国家高新技术企业, 云南省本土全品类、全地域、全流程的医疗设备维保企业。同时，为云南省医疗器械行业协会会长单位、云南省国际贸易投资商会副会长单位，是昆明理工大学、大理大学等多家高校的实习培训基地。至2024年，员工人数301人，办公场地1500平米，设备仓库总面积10000平米。
                </div>
              </div>
              <el-image
                class="bt-introduce-image"
                :src="introduceImage"
                fit="fill"
              >
              </el-image>
            </div>
            <!-- 基础数据显示 -->
            <ul class="bt-base-list">
              <li v-for="(item, index) in baseNumberList" :key="index">
                <div class="number">
                  <count-to class="bt-number-text" :endVal="item.number"></count-to
                  ><span>{{ item.unit }}</span>
                </div>
                <div class="name">{{ item.name }}</div>
              </li>
            </ul>
          </div>
        </swiper-slide>

        <swiper-slide class="bt-history">
          <div v-show="swiperIndex == 2" class="bt-introduce">
            <div class="bt-home-title">
              发展历程<span class="bt-home-subtitle">DEVELOPMENT PATH</span>
            </div>
            <ul class="history-list">
              <li v-for="(item, index) in historyList" :key="index">
                <div class="time">{{ item.time }}年</div>
                <div class="context">{{ item.context }}</div>
              </li>
            </ul>
          </div>
        </swiper-slide>

        <swiper-slide class="bt-culture">
          <div v-show="swiperIndex == 3" class="bt-introduce">
            <div class="bt-home-title">
              企业文化<span class="bt-home-subtitle">CORPORATE CULTURE</span>
            </div>
            <ul class="bt-culture-list">
              <li v-for="(item, index) in cultureList" :key="index">
                <div class="bt-culture-name">
                  <div class="bt-culture-icon">
                    <el-image :src="item.icon" fit="fill"></el-image>
                  </div>
                  <span>{{ item.name }}</span>
                </div>
                <div
                  class="bt-culture-text"
                  v-for="(child, childIndex) in item.children"
                  :key="childIndex"
                >
                  {{ child.name }}
                </div>
              </li>
            </ul>
          </div>
        </swiper-slide>

        <swiper-slide class="bt-culture">
          <div v-show="swiperIndex == 4" class="bt-introduce">
            <div class="bt-home-title">
              企业荣誉<span class="bt-home-subtitle">ENTERPRISE HONOR</span>
            </div>
            <ul class="bt-honor-main">
              <li v-for="(item, index) in honorMainList" :key="index">
                <el-image
                  class="bt-honor-image0"
                  :src="item.image"
                  fit="fill"
                  :preview-src-list="honorMainProList"
                ></el-image>
                <div class="bt-honor-name">{{ item.name }}</div>
              </li>
            </ul>
            <ul class="bt-honor-soft">
              <li v-for="(item, index) in honorSoftList" :key="index">
                <el-image
                  class="bt-honor-image1"
                  :src="item"
                  fit="fill"
                  :preview-src-list="honorSoftList"
                ></el-image>
              </li>
            </ul>
            <div class="bt-honor-title">系列软件著作权证书</div>
            <ul class="bt-honor-soft">
              <li v-for="(item, index) in honorISOList" :key="index">
                <el-image
                  class="bt-honor-image1"
                  :src="item"
                  fit="fill"
                  :preview-src-list="honorISOList"
                ></el-image>
              </li>
            </ul>
            <div class="bt-honor-title">系列ISO质量管理体系认证证书</div>
          </div>
        </swiper-slide>

        <swiper-slide class="bt-culture">
          <div v-show="swiperIndex == 5" class="bt-introduce">
            <div class="bt-home-title">
              企业资质<span class="bt-home-subtitle">ENTERPRISE  CERTIFICATE</span>
            </div>
            <ul class="bt-enterprise-main">
              <li v-for="(item, index) in enterpriseMainList" :key="index">
                <el-image
                  class="bt-honor-image2"
                  :src="item.image"
                  fit="fill"
                  :preview-src-list="enterpriseMainProList"
                ></el-image>
                <div class="bt-honor-name">{{ item.name }}</div>
              </li>
            </ul>
            <ul class="bt-honor-soft">
              <li v-for="(item, index) in enterpriseSeviceList" :key="index">
                <el-image
                  class="bt-honor-image1"
                  :src="item"
                  fit="fill"
                  :preview-src-list="enterpriseSeviceList"
                ></el-image>
              </li>
            </ul>
            <div class="bt-honor-title">维尔系列服务/诚信认证证书</div>
          </div>
        </swiper-slide> 

        <swiper-slide class="bt-culture">
          <div v-show="swiperIndex == 6" class="bt-introduce">
            <div class="bt-home-title">
              合作医院<span class="bt-home-subtitle">COOPERATE  HOSPITAL</span>
            </div>
            <ul class="bt-hospital-main">
              <li v-for="(item, index) in hospitalList" :key="index">
                <el-image
                  class="bt-hospital-image"
                  :src="item.image"
                  fit="fill"
                ></el-image>
                <div class="bt-hospital-name">{{ item.name }}</div>
              </li>
            </ul>
          </div>
        </swiper-slide> 

        <!-- 联系我们 -->
        <swiper-slide class="bt-culture">
          <div v-show="swiperIndex == 7"> 
            <contact-us></contact-us>
            <Footer></Footer>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { screenWIidth } from '@/utils/baseText';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import CountTo from "vue-count-to";
import Footer from "../layout/footer.vue";
import ContactUs from '../contactus/index'
import {
  baseNumberList,
  historyList,
  cultureList,
  honorMainList,
  honorMainProList,
  honorSoftList,
  honorISOList,
  enterpriseMainList,
  enterpriseMainProList,
  enterpriseSeviceList,
  hospitalList
} from "./list.js";
export default {
  components: {
    CountTo,
    Footer,
    swiper,
    swiperSlide,
    ContactUs,
  },
  data() {
    let _this = this;
    return {
      isScreenIndex: false,
      timer: '',
      swiperIndex: 0,
      conHeight: window.innerHeight - 60 + 'px',
      bannerImage: require("@/assets/images/home_banner.jpg"),
      introduceImage: require("@/assets/images/introduce.jpg"),

      baseNumberList: baseNumberList, // 基础数据
      historyList: historyList, // 发展历程
      cultureList: cultureList, //企业文化
      honorMainList: honorMainList, // 企业荣誉证书
      honorMainProList: honorMainProList,  //企业荣誉证书全屏查看列表
      honorSoftList: honorSoftList, // 系列软件著作权证书
      honorISOList: honorISOList, // 系列ISO质量管理体系认证证书
      enterpriseMainList: enterpriseMainList, //企业资质
      enterpriseMainProList: enterpriseMainProList, //企业资质全屏查看列表
      enterpriseSeviceList: enterpriseSeviceList, //维尔系列服务/诚信认证证书
      hospitalList: hospitalList, //合作医院

      swiperOptions: {
        // speed: 800,
        initialSlide: 0,
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //垂直方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "my-bullet", //自定义分页器样式
          bulletActiveClass: "my-bullet-active", //自定义当前选中样式
          clickable: true, //点击切换轮播
        },
        on: {
          // 获取到的this为Swiper对象
          // 使用_this作为全局Vue对象
          slideChangeTransitionStart: function() {
            _this.swiperIndex = this.activeIndex;
            _this.$store.commit('isHomeFirst', this.activeIndex == 0 ? true : false);
          }
        }
      },
    };
  },
  watch: {
    // 监听路由传值 初始化swiper默认选中
    $route: {
      handler: function(val) {
        this.swiperIndex = val.query.id | 0;
        this.$store.commit('isHomeFirst', this.swiperIndex == 0 ? true : false);
        this.swiperInit();
      }
    }
  },
  mounted() {
    // 获取路由传过来的id，确定swiper默认选中
    this.swiperIndex = parseInt(this.$route.query.id) | 0;
    this.swiperInit();
    if(document.body.clientWidth <= screenWIidth) {
      this.isScreenIndex = true;
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    swiperInit() {
      this.swiperOptions.initialSlide = this.swiperIndex;
      this.timer = new Date().getTime();
    },
  },
};
</script>

<style scoped lang="scss">
// swiper style
.my-bullet {
  display: block;
  width: 11px;
  height: 11px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 10px 0;
}
.my-bullet-active {
  width: 11px;
  height: 25px;
  background: #fff;
  border-radius: 20px;
  margin: 10px 0;
}
.banner-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 420px;
  height: 40vh;
  text-align: center;
  color: $themes_color;
  font-size: 42px;
  text-align-last: justify;
  .bt-banner-title-text {
    margin-bottom: 10px; 
    font-size: 80px; 
    font-weight: bold;
    animation: titleMove 1s 0.3s linear;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: scale(0.2);
  }
  div {
    margin-bottom: 6px;
  }
}
.bt-banner-title1 {
  font-size: 36px;
  animation: title1Move 1s 0.3s linear;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-left: -300px;
}
.bt-banner-title2 {
  animation: title2Move 1s 0.3s linear;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-right: -300px;
}
.bt-banner-title3 {
  animation: title3Move 1s 0.3s linear;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-left: -300px;
}

@keyframes titleMove {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes title1Move {
  0% {
    opacity: 0;
    margin-left: -300px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes title2Move {
  0% {
    opacity: 0;
    margin-right: -300px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes title3Move {
  0% {
    opacity: 0;
    margin-left: -300px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@media Screen and (max-width: $screen_width) {
  .banner-title {
    width: 250px;
    font-size: 24px;
    .bt-banner-title-text {
      font-size: 50px;
    }
  }
  .bt-banner-title1 {
    font-size: 22px;
  }
}


.bt-introduce {
  width: $min_width;
  margin: 0 auto;
}

.bt-home-title {
  margin-bottom: 60px;
  padding-top: 5vh;
  font-size: 4vh;
  color: $themes_color;
  .bt-home-subtitle {
    vertical-align: middle;
    margin-left: 1vw;
    font-size: 1.8vh;
    font-weight: lighter;
    color: #999999;
  }
}
@media Screen and (max-width: $screen_width) {
  .bt-introduce {
    width: calc(100% - 20px);
    margin: 0 auto;
    padding: 0 10px;
  }
  .bt-home-title {
    margin-bottom: 10px;
    padding-top: 2vh;
    font-size: 3vh;
  }
}

// 集团介绍
.bt-introduce-container {
  display: flex;
  .bt-introduce-text {
    flex: 1;
    margin-right: 30px;
    height: 55vh;
    overflow: auto;
  }
  .bt-name {
    margin-bottom: 1vh;
    font-size: 26px;
    font-weight: bolder;
    color: $themes_color;
  }
}
.bt-introduce-content {
  display: flex;
}
.bt-introduce-content-text {
  margin-bottom: 20px;
  line-height: 36px;
  font-size: 18px;
  color: #666;
  text-indent: 2rem;
}
.bt-introduce-image {
  width: 440px; 
  height: 55vh
}
@media Screen and (max-width: $screen_width) {
  .bt-introduce-container {
    display: block;
    .bt-name {
      font-size: 16px;
    }
    .bt-introduce-text {
      margin-right: 0;
      height: auto;
    }
  }
  .bt-name {
    font-size: 18px;
  }
  .bt-introduce-content {
    display: block;
  }
  .bt-introduce-content-text {
    margin-bottom: 6px;
    line-height: 22px;
    font-size: 13px;
  }
  .bt-introduce-image {
    display: none;
  }
}

// 基础数据显示
.bt-base-list {
  margin-top: 2vh;
  display: flex;
  li {
    flex: 1;
    text-align: center;
  }
  .number {
    span {
      font-size: 3vh;
      font-weight: lighter;
    }
    .bt-number-text {
      font-size: 6vh;
      font-weight: bolder;
      color: $themes_color;
    }
  }
  .name {
    margin-top: 1vh;
    font-size: 3vh;
    color: #333;
  }
}
@media Screen and (max-width: $screen_width) {
  .bt-base-list {
    .number {
      span {
        font-size: 1.5vh;
      }
      .bt-number-text {
        font-size: 3vh;
      }
    }
    .name {
      font-size: 1.3vh;
    }
  }
  
}

// 发展历程
.bt-history {
  background-image: url("../../assets/images/history-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.history-list {
  padding-top: 0;
  display: flex;
  li {
    flex: 1;
    border-bottom: 10px solid #999;
    border-right: 2px solid #999;
    padding: 0 6px;
    height: 250px;
    overflow: auto;
    .time {
      text-align: center;
      font-size: 26px;
      font-weight: bolder;
      color: $themes_color;
    }
    .context {
      font-size: 14px;
      color: #666;
    }
  }
  li:nth-child(7), li:nth-child(8) {
    flex: 1.5;
  }
  li:nth-child(2n) {
    margin-top: 250px;
    border-bottom: none;
    border-top-width: 10px;
    border-top-style: solid;
    border-top-color: $themes_color;
    .time {
      padding-top: 20px;
    }
  }
}

@media Screen and (max-width: $screen_width) {
  .history-list {
    padding-top: 1vh;
    display: block;
    li {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      margin-bottom: 2vh;
      padding: 1vh;
      width: calc(50% - 2vh);
      border: none;
      height: auto;
      .time {
        margin-bottom: 1vh;
        font-size: 2.2vh;
      }
      .context {
        font-size: 1.6vh;
      }
    }
    li:nth-child(2n) {
      margin-top: 0;
      border: none;
      border-top-color: $themes_color;
      .time {
        padding-top: 0;
      }
    }
  }
}

// 企业文化
.bt-culture-list {
  padding-top: 6vh;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-bottom: 10vh;
    width: 50%;
  }
}
.bt-culture-name {
  margin-bottom: 2vh;
  position: relative;
  padding-right: 40px;
  display: inline-block;
  line-height: 80px;
  background-color: $themes_color;
  border-radius: 40px;
  font-size: 42px;
  color: #fff;
  span {
    padding-left: 100px;
  }
  .bt-culture-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 50px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 80px;
      height: 80px;
      background-color: $themes_color;
      border-radius: 50px;
    }
  }
  .el-image {
    margin-top: 25px;
    margin-left: 25px;
    width: 50px;
    height: 50px;
    z-index: 1;
  }
}
.bt-culture-text {
  margin-top: 2vh;
  font-size: 3vh;
  color: #666;
}
@media Screen and (max-width: $screen_width) {
.bt-culture-list {
  margin-top: 4vh;
  padding-top: 0;
  display: block;
  li {
    margin-bottom: 4vh;
    width: 100%;
  }
}
.bt-culture-name {
  margin-bottom: 0.6vh;
  line-height: 4vh;
  border-radius: 4vh;
  font-size: 1.8vh;
  span {
    padding-left: 5vh;
  }
  .bt-culture-icon {
    top: 0;
    left: -0.5vh;
    width: 4vh;
    height: 4vh;
    border-radius: 2vh;
    &::after {
      top: 0.25vh;
      left: 0.25vh;
      width: 3.5vh;
      height: 3.5vh;
    }
  }
  .el-image {
    margin-top: 1vh;
    margin-left: 1vh;
    width: 2vh;
    height: 2vh;
  }
}
.bt-culture-text {
  margin-left: 20vw;
  margin-top: 1vh;
  font-size: 2vh;
}

}

// 荣誉资质
.bt-honor-main {
  margin-top: -3vh;
  display: flex;
  text-align: center;
  margin-bottom: 3vh;
  li {
    flex: 1;
    text-align: center;
  }
  li:nth-child(2) {
    .bt-honor-name {
      margin: 0 auto;
      width: 160px;
    }
  }
}
.bt-honor-name {
  font-size: 20px;
  color: #666;
}
.bt-honor-title {
  margin-bottom: 3vh;
  text-align: center;
  font-size: 20px;
  color: #666;
  animation: honorMove 1s 1.5s linear;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(50%);
} 
.bt-honor-image0 {
  height: 17vh;
  width: 220px;
}
.bt-honor-image1 {
  height: 20vh;
  width: 140px;
}
@media Screen and (max-width: $screen_width) {
.bt-honor-main {
  margin-top: 3vh;
  display: block;
  li {
    margin-bottom: 1vh;
    vertical-align: top;
    display: inline-block;
    width: 50%;
  }
  li:nth-child(2) {
    .bt-honor-name {
      width: 80%;
    }
  }
}
.bt-honor-name {
  font-size: 1.4vh;
}
.bt-honor-image0 {
  height: 16vh;
  width: 40vw;
}
.bt-honor-image1 {
  height: 10vh;
  width: 100%;
}
.bt-honor-title {
  font-size: 1.4vh;
}
  
}


@keyframes honorMove {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.bt-honor-soft {
  display: flex;
  justify-content: center;
}

.bt-enterprise-main {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-bottom: 20px;
    width: 25%;
    text-align: center;
  }
  .bt-honor-image2 {
    height: 18vh;
  }
  li:nth-child(5) {
    .bt-honor-name {
      margin: 0 auto;
      width: 180px;
    }
  }
}
@media Screen and (max-width: $screen_width) {
  .bt-enterprise-main {
    display: block;
    li {
      display: inline-block;
      width: 33.333%;
      vertical-align: top;
      .bt-honor-name {
        margin: 0 auto;
        width: 24vw;
      }
    }
    .bt-honor-image2 {
      height: 12vh;
      width: 30vw;
    }
    li:nth-child(5) {
      .bt-honor-name {
        margin: 0 auto;
        width: 24vw;
      }
    }
  }
}

.bt-hospital-main {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2vh;
    width: 12.28%;
    text-align: center;
    .bt-hospital-image {
      width: 70px;
    }
    .bt-hospital-name {
      font-size: 1.4vh;
      color: #333;
    }
  }
}
@media Screen and (max-width: $screen_width) {
  .bt-hospital-main {
    li {
      width: 12.28%;
      margin-bottom: 1vh;
      .bt-hospital-image {
        width: 40px;
      }
      .bt-hospital-name {
        font-size: 1.2vh;
      }
    }
  }
}
</style>